// This Source Code Form is subject to the terms of the Mozilla Public
// License, v2.0. If a copy of the MPL was not distributed with this
// file, You can obtain one at http://mozilla.org/MPL/2.0/

import {
  IDataSourceFactory,
  DataSourceFactoryInitializeArgs,
} from "@foxglove/studio-base/context/PlayerSelectionContext";
import { IterablePlayer, WorkerIterableSource } from "@foxglove/studio-base/players/IterablePlayer";
import { Player } from "@foxglove/studio-base/players/types";

export default class McapRemoteDataSourceFactory implements IDataSourceFactory {
  public id = "mcap-remote-file";
  public type: IDataSourceFactory["type"] = "remote-file";
  public displayName = "MCAP";
  public iconName: IDataSourceFactory["iconName"] = "FileASPX";
  public supportedFileTypes = [".mcap"];
  public description = "Fetch and load pre-recorded MCAP files from a remote location.";
  public docsLink = "https://foxglove.dev/docs/studio/connection/mcap";

  public initialize(args: DataSourceFactoryInitializeArgs): Player | undefined {
    const url = args.params?.url;
    if (!url) {
      return;
    }
    console.log("url", url);
    // console.log(args);

    const targetTimeStamp = args.params?.targetTimeStamp ?? "";

    const source = new WorkerIterableSource({
      sourceType: "mcap",
      initArgs: { url },
    });

    return new IterablePlayer({
      metricsCollector: args.metricsCollector,
      source,
      name: url,
      sourceId: this.id,
      urlParams: {
        targetTimeStamp,
        url,
      },
    });
  }
}
